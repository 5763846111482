/* eslint-disable */

// imports the original component from vendor folder
import SourceProductCard from 'SourceComponent/ProductCard/ProductCard.component';

// import dependencies
import Loader from 'Component/Loader';
import Fade from 'react-reveal/Fade';
import TextPlaceholder from 'Component/TextPlaceholder';
import Html from 'Component/Html';
import Image from 'Component/Image';
import ProductPrice from 'Component/ProductPrice';

import { SIMPLE, CONFIGURABLE } from 'Util/Product';

// import new extended styles file
import './ProductCard.override.style.scss';
import {
    OPTION_TYPE_IMAGE_FALLBACK,
    validOptionTypes
} from './ProductCard.config';

import {
    calculateFinalPrice,
    formatPrice,
    roundPrice
} from 'Util/Price';


export class ProductCard extends SourceProductCard {
    saveTypeRenderMap = {
        [SIMPLE]: __('Save'),
        [CONFIGURABLE]: __('Save up to')
    }

    renderSalesPercentage() {
        const {
            product: {
                price_range: {
                    maximum_price: { discount: { percent_off: maxPercentOff = 0 } = {} } = {},
                    minimum_price: { discount: { percent_off: minPercentOff = 0 } = {} } = {}
                } = {},
                type_id
            } = {},
        } = this.props;

        const label = this.saveTypeRenderMap[type_id] || __('Save');

        const offPercent = Math.round(Math.max(minPercentOff, maxPercentOff));
        if (!offPercent || offPercent === 0 || isNaN(offPercent)) {
            return null;
        } else {
            return <p block="PercentageOff">{label} {offPercent}%</p>;
        }
    }

    renderShineLabel() {
        const {
            product:
            {
                attributes: {
                    glans_label
                } = {}
            },
            product:
            {
                attributes: {
                    glans_label: { attribute_value: glans_label_option_id } = {}
                } = {}
            }
        } = this.props;

        const {
            product:
            {
                attributes: {
                    glans_label: { attribute_options: { [glans_label_option_id]: { label: glans_label_option_label } = {} } = {} } = {}
                } = {}
            }
        } = this.props;

        if (!glans_label) {
            return null;
        }

        return (

            <p
                block="ProductCard"
                elem="GlansLabel"
            >
                {glans_label_option_label}
            </p>
        );
    }


    renderFixedLowPriceLabel() {
        const {
            product: {
                attributes: {
                    fixed_low_price: { attribute_value: isFixedLowPrice } = {}
                } = {}
            }
        } = this.props;
    
        // Check if the attribute exists and is set to "Yes"
        if (!isFixedLowPrice || isFixedLowPrice !== '1') {
            return null;
        }
    
        // Render the label if the attribute is "Yes"
        return (
            <p block="ProductCard" elem="FixedLowPriceLabel">
                {__('Fixed Low Price')}
            </p>
        );
    }


    renderAttributeLabel() {
        const {
            product:
            {
                attributes: {
                    size_liter,
                    size_measure
                } = {}
            }
        } = this.props;

        let selected_attribute = false;
        let selected_attribute_suffix = '';

        if (size_measure) {
            selected_attribute = size_measure;
        }

        if (size_liter) {
            selected_attribute = size_liter;
            selected_attribute_suffix = ' L';
        }

        if (!selected_attribute) {
            return;
        }

        let attribute_labels = [];

        let attribute_value = '';
        let counter = 0;
        let labels_omitted = 0;
        let labels_total_characters = 0;

        const num_labels = Object.keys(selected_attribute.attribute_options).length;

        // Control how many labels are to be shown for each "breakpoint" / view
        let labels_limit = 3;
        let labels_total_characters_limit = 21;

        if (window.innerWidth < 567) {
            labels_total_characters_limit = 18;
            labels_limit = 2;
        } else if (window.innerWidth > 566 && window.innerWidth < 1024) {
            labels_total_characters_limit = 25;
            labels_limit = 4;
        } else if (window.innerWidth > 1399) {
            labels_total_characters_limit = 25;
            labels_limit = 4;
        }

        const total_labels = Object.keys(selected_attribute.attribute_options).length;

        if (!total_labels > 0) {
            return;
        }

        // Processing - Get correct values and put them in an array
        Object.keys(selected_attribute.attribute_options).forEach(obj => {

            switch (selected_attribute.attribute_code) {
                case 'size_measure':
                    // Example value: "25 mm x 50 m"
                    attribute_value = selected_attribute.attribute_options[obj].label;
                    break;
                case 'size_liter':
                    // Example value: "10 liter"

                    // Only get the number
                    attribute_value = selected_attribute.attribute_options[obj].label.split(" ")[0];
                    break;
            }

            attribute_labels.push(attribute_value + selected_attribute_suffix);
            counter++;
            labels_total_characters += attribute_value.length;

        });

        // Sorting, only when there are labels and more than one.
        if (attribute_labels.length > 1) {

            attribute_labels.sort((a, b) => {

                if (Number.parseInt(a) > Number.parseInt(b)) {
                    return 1;
                }

                if (Number.parseInt(a) < Number.parseInt(b)) {
                    return -1;
                }

                return 0;

            });
        }

        // Limit labels (We can't show them all, every time on any device / dimension)

        // Process if the labels are too long
        if (labels_total_characters > labels_total_characters_limit && total_labels > 1) {

            let character_count = 0;
            let loop_count = 0;

            attribute_labels.forEach(label => {

                character_count += label.length;

                if (character_count > labels_total_characters_limit) {
                    return;
                }

                loop_count++;
            });

            if (loop_count > labels_limit && total_labels > labels_limit) {
                labels_omitted = total_labels - labels_limit + 1;
            } else {
                labels_omitted = total_labels - loop_count;
            }

        } else if (total_labels > labels_limit) {
            labels_omitted = total_labels - labels_limit + 1;
        }

        if (labels_omitted > 0) {
            attribute_labels.splice(attribute_labels.length - labels_omitted, labels_omitted);
            attribute_labels.push("+ " + labels_omitted);
        }

        return (
            <div
                block="ProductCard"
                elem="AttributeContainer"
            >
                {attribute_labels.map((attribute_value) => {
                    return (
                        <div block="ProductCard" elem="AttributeLabel" key={ attribute_value }>
                            {attribute_value}
                        </div>
                    )
                }
                )}
            </div>
        );
    }

    renderInfoBoxContent() {
        const {
            product,
            product: {
                short_description,
                short_description: {
                    html: short_desc_html
                }
            }
        } = this.props;

        return (
            <div
                block="InfoCard"
                elem="Content"
                mods={{ isLoaded: !!short_desc_html }}
            >
                <Html content={short_desc_html} />
            </div>
        );
    }

    render() {
        const {
            children,
            mix,
            isLoading,
            siblingsHaveBrands,
            siblingsHavePriceBadge,
            siblingsHaveTierPrice,
            siblingsHaveConfigurableOptions,
            layout,
        } = this.props;

        const mods = {
            siblingsHaveBrands,
            siblingsHavePriceBadge,
            siblingsHaveTierPrice,
            siblingsHaveConfigurableOptions,
        };

        const {
            product: {
                image,
                attributes: {
                    infobox_type,
                    infobox_type: { attribute_value: infobox_type_id } = {}
                } = {},
                id
            }
        } = this.props;

        if (infobox_type && image) {

            const {
                product: {
                    image: { url: image_url }
                }
            } = this.props;

            if (infobox_type_id == 1072) { // 1 kolonne med tekst
                var infobox_span = 'one';
                var infobox_show_image = false;
                var use_columns = false;
            }

            else if (infobox_type_id == 1073) { // 1 kolonne med baggrund og evt. tekst
                var infobox_span = 'one';
                var infobox_show_image = true;
                var use_columns = false;
            }

            else if (infobox_type_id == 1074) { // 2 kolonner med tekst til venstre og billede til højre
                var infobox_span = 'two';
                var infobox_show_image = true;
                var use_columns = true;
            }

            else if (infobox_type_id == 1075) { // 2 kolonner med baggrund og evt. tekst
                var infobox_span = 'two';
                var infobox_show_image = true;
                var use_columns = false;
            }

            else { // Fallback
                var infobox_span = 'one';
                var infobox_show_image = false;
                var use_columns = false;
            }

            if (infobox_show_image == true) {

                const background_image_style = { backgroundImage: 'url(' + image_url + ')' };

                return (
                    <Fade duration={750}>
                        <li block="InfoCard" mods={{ ...mods, span: infobox_span, isInfobox: true, hasImage: infobox_show_image, useColumns: use_columns }} mix={ {...mix, block: 'ProductCard' } }>
                            <Loader isLoading={isLoading} />
                            <div
                                block="InfoCard"
                                elem="Column"
                                mods={{ colType: 'image' }}
                            >
                                <div
                                    block="InfoCard"
                                    elem="Image"
                                    style={background_image_style}
                                />
                            </div>
                            <div
                                block="InfoCard"
                                elem="Column"
                                mods={{ colType: 'content' }}
                            >
                                {this.renderInfoBoxContent()}
                            </div>
                        </li>
                    </Fade>
                );
            } else {
                return (
                    <Fade duration={750}>
                        <li block="InfoCard" mods={{ ...mods, span: infobox_span, isInfobox: true }} mix={ { ...mix, block: 'ProductCard' } }>
                            <Loader isLoading={isLoading} />
                            <div
                                block="InfoCard"
                                elem="Column"
                                mods={{ colType: 'content' }}
                            >
                                {this.renderInfoBoxContent()}
                            </div>
                        </li>
                    </Fade>
                );
            }


        }

        if (layout === 'list') {
            return (
                <Fade bottom small duration={750}>
                    <li block="ProductCard" mods={{ ...mods, id }} mix={mix}>
                        <Loader isLoading={isLoading} />
                        {this.renderCardListContent()}
                    </li>
                </Fade>
            );
        }

        return (
            <Fade duration={750}>
                <li block="ProductCard" mods={{ ...mods, id }} mix={mix} onClick={this.setHoeksProductId.bind(this)}>
                    <Loader isLoading={isLoading} />
                    {this.renderSalesPercentage()}
                    {this.renderFixedLowPriceLabel()}
                    {this.renderShineLabel()}
                    {this.renderCardContent()}
                    {this.renderProductActions()}
                    <div block="ProductCard" elem="AdditionalContent">
                        {children}
                    </div>
                </li>
            </Fade>
        );
    }

    renderMainDetails() {
        const { product: { name } } = this.props;

        return (
            <p
              block="ProductCard"
              elem="Name"
              mods={ { isLoaded: !!name } }
            >
                <TextPlaceholder content={ name } length="medium" />
            </p>
        );
    }

    renderCardContent() {
        const { renderContent } = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return (
            this.renderCardLinkWrapper((
                <>
                    <div block="ProductCard" elem="FigureReview">
                        <figure block="ProductCard" elem="Figure">
                            {this.renderPicture()}
                            {this.renderAttributeLabel()}
                            
                        </figure>
                        {this.renderReviews()}
                    </div>
                    <div block="ProductCard" elem="Content">
                        {this.renderAdditionalProductDetails()}
                        {this.renderMainDetails()}
                        {this.renderProductPrice()}
                        {this.renderVisualConfigurableOptions()}
                    </div>
                </>
            ))
        );
    }

    setHoeksProductId() {
        const {
            product: {
                id
            } = {}
        } = this.props;

        window.hoeksProductId = id;
    }

    getImagesSources() {
        const { product: { small_image: { url }, small_image_sd: { url: urlSd } } } = this.props;
        return `${urlSd} 341w, ${url} 682w`;
    }

    renderPicture(mix = {}) {
        const { product: { id, name, small_image, small_image_sd }, device, thumbnail = {} } = this.props;
        const src = device.small && small_image_sd ? small_image_sd?.url : thumbnail;

        if (id == '34072') {
            console.log('debug', src);
        }
        // const sources = src ? this.getImagesSources() : null;
        // const sizes = "(max-width: 600px) 341px, 682px";
        this.sharedComponent = (
            <Image
                imageRef={this.imageRef}
                src={src}
                // srcSet={sources}
                // sizes={sizes}
                alt={name}
                ratio="custom"
                mix={{ block: 'ProductCard', elem: 'Picture', mix }}
                isPlaceholder={!id}
            />
        );

        return (
            <>
                {this.sharedComponent}
            </>
        );
    }

    renderPricePerUnit() {

        const {
            product: {
                price_range: {
                    minimum_price: {
                        discount: {
                            percent_off: discountPercentage
                        } = {},
                        final_price: {
                            value: minimalPriceValue,
                            currency: priceCurrency
                        } = {},
                        final_price_excl_tax: {
                            value: minimalPriceExclTaxValue
                        } = {},
                        regular_price: {
                            value: regularPriceValue
                        } = {},
                        regular_price_excl_tax: {
                            value: regularPriceExclTaxValue
                        } = {}
                    } = {}
                } = {}
            } = {}
        } = this.props;

        const {
            product: {
                length_cm,
                length_m,
                panels_instruction_guide
            }
        } = this.props;

        let is_custom_image_product = "0";

        if (this.props.product.attributes !== undefined && this.props.product.attributes.is_custom_image_product !== undefined) {
            //      console.log( this.props.product.attributes.is_custom_image_product);
            is_custom_image_product = (this.props.product.attributes.is_custom_image_product.attribute_value == "1") ? "1" : "0";
        }

        const finalPrice = calculateFinalPrice(discountPercentage, minimalPriceValue, regularPriceValue);
        let productType = "";
        let pricePerUnit = 0;
        let formattedPricePerUnit = "";

        // Stuk (not panels)
        if (length_cm !== undefined && length_cm > 100 && panels_instruction_guide !== undefined && panels_instruction_guide == null) {
            pricePerUnit = finalPrice / (length_cm / 100);
            //formattedPricePerUnit = formatPrice(pricePerUnit, priceCurrency) + __(" / m");
            formattedPricePerUnit = (
                <div className="PricePerUnit-Stuk">
                    {formatPrice(pricePerUnit, priceCurrency)} {__(" / m")}
                </div>
            );
        }
        // Tapet
        else if (length_m !== undefined && length_m > 1) {
            // 	pricePerUnit = Math.round(((finalPrice / Math.floor(length_m * 100)) * 100) * 10) / 10;

            // 	/* If per meter */
            // 	// formattedPricePerUnit = formatPrice(pricePerUnit, priceCurrency) + " / m";

            // 	/* Else per roll */
            formattedPricePerUnit = __("Price / roll");
        }
        else if (length_m !== undefined && length_m == "1") {
            formattedPricePerUnit = __("Price / meter");
        }
        else if (is_custom_image_product !== undefined && is_custom_image_product == "1") {
            formattedPricePerUnit = __("Price / m²");
        }

        return (
            <div
                block="ProductCard"
                elem="pricePerUnit"
            >
                {formattedPricePerUnit}
            </div>
        )
    }

    renderProductPrice() {
        const {
            product: { price_range },
        } = this.props;

        if (!price_range) {
            return <TextPlaceholder />;
        }

        return (
            <div block="ProductCard" elem="PriceWrapper">

                {this.renderTierPrice()}
                {this.renderProductTypePriceBadge()}
                <ProductPrice
                    price={price_range}
                    mix={{ block: 'ProductCard', elem: 'Price' }}
                />
                {this.renderPricePerUnit()}
            </div>
        );
    }

}

export default ProductCard;
