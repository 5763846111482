/* eslint-disable */
import Popup from 'Component/Popup';
import Field from 'Component/Field';
import Loader from 'Component/Loader';
import { TEXT_TYPE } from 'Component/Field/Field.config';

import './ColorSwatchPicker.style.scss';

/** @namespace Component/ColorSwatchPicker/Component */
export class ColorSwatchPicker extends PureComponent {
    renderPopup() {
        const {
            popupId
        } = this.props;

        return (
            <Popup
                id={ popupId }
                clickOutside={ true }
                mix={ { block: "ColorSwatchPicker", elem: "Popup" } }
                contentMix={ { block: "ColorSwatchPicker", elem: "PopupContent" } }
            >
                { this.renderColorTabs() }
            </Popup>
        )
    }

    renderColorTabs = () => {
        const {
            colorCollections,
            onCollectionSelect,
            selectedCollection
        } = this.props;

        if (!colorCollections.length) {
            return (
                <Loader isLoading={ true } />
            )
        }

        return (
            <>
                <button
                    onClick={ () => onCollectionSelect(null) }
                    block="ColorSwatchPicker"
                    elem="CollectionButton"
                    mods={ { type: 'all', isActive: selectedCollection === null } }
                    mix={ { block: 'Button' } }
                >
                    { __('All') }
                </button>
                { colorCollections.map(this.renderColorTab) }
                { colorCollections.map(this.renderColorList) }
            </>
        )
    }

    renderColorTab = (colorCollection) => {
        const {
            name = ''
        } = colorCollection;

        const {
            onCollectionSelect,
            selectedCollection
        } = this.props;

        return (
            <button
                key={ name }
                onClick={ () => onCollectionSelect(name) }
                block="ColorSwatchPicker"
                elem="CollectionButton"
                mods={ { type: name.toLowerCase().replace(/\s+/g, ''), isActive: selectedCollection === name } }
                mix={ { block: 'Button' } }
            >
                { name }
            </button>
        )
    }

    renderColorList = (colorCollection) => {
        const {
            name,
            colors
        } = colorCollection;

        const {
            selectedCollection
        } = this.props;

        return (
            <div
                key={ name }
                block="ColorSwatchPicker"
                elem="ColorList"
                mods={ { isActive: name === selectedCollection || selectedCollection === null } }
            >
                { colors.map(this.renderColorSwatch) }
            </div>
        )
    }

    renderColorSwatch = (color) => {
        const {
            code,
            name,
            hexadecimal,
            image_name
        } = color;

        const {
            onColorSelect
        } = this.props;

        return (
            <div
                block="ColorSwatchPicker"
                elem="Color"
                key={ `${code}-${hexadecimal}-${name}` }
                onClick={ () => onColorSelect(`${code} ${name}`) }
            >
                {hexadecimal ? this.renderHexdecimalColorArea(hexadecimal) : this.renderImageColorArea(image_name)}
                <p
                    block="ColorSwatchPicker"
                    elem="ColorText"
                >
                    <span
                        block="ColorSwatchPicker"
                        elem="ColorCode"
                    >{ code }</span>
                    { ' ' }
                    <span
                        block="ColorSwatchPicker"
                        elem="ColorName"
                    >{ name }</span>
                </p>
            </div>
        )
    }

    renderImageColorArea(imageName) {
        return (
            <div
                block="ColorSwatchPicker"
                elem="ColorArea"
                style={{ backgroundImage:  `url("/media/farvekort/${imageName}")`}}
            />
        );
    }

    renderHexdecimalColorArea(hexadecimal) {
        return (
            <div
                block="ColorSwatchPicker"
                elem="ColorArea"
                style={{ backgroundColor: hexadecimal }}
            />
        );
    }

    renderButton() {
        const {
            onPopupOpenClick,
            option: {
                title
            }
        } = this.props;

        return (
            <button
                block="ColorSwatchPicker"
                elem="Button"
                mix={ { block: 'Button' } }
                onClick={ onPopupOpenClick }
            >
                { __('Choose color') }
            </button>
        )
    }

    renderInput() {
        const {
            fieldValue = '',
            option: {
                option_id
            } = {}
        } = this.props;

        return (
            <Field
                type={ TEXT_TYPE }
                name={ `color-${option_id}` }
                id={ `color-${option_id}` }
                // isDisabled={ true }
                value={ fieldValue }
            />
        )
    }

    render() {
        return (
            <>
                { this.renderButton() }
                { this.renderInput() }
                { this.renderPopup() }
            </>
        )
    }
}

export default ColorSwatchPicker;